import React from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import SecondaryHeading from "@atoms/SecondaryHeading"

import Container from "./styles"

const TitleBlock = ({ primary }) => {
  const title = primary.main_title && RichText.asText(primary.main_title)
  const secondaryTitle =
    primary.secondary_title && RichText.asText(primary.secondary_title)

  return (
    <Container pt={3} pb={{ xs: 3, md: 0 }}>
      <SecondaryHeading secondary={secondaryTitle}>{title}</SecondaryHeading>
    </Container>
  )
}

TitleBlock.propTypes = {
  primary: PropTypes.shape({
    main_title: PropTypes.array.isRequired,
    secondary_title: PropTypes.array.isRequired,
  }),
}

export default TitleBlock
