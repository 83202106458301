/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import PropTypes from "prop-types"
import { Box } from "@xstyled/styled-components"

import { Main, Secondary } from "./styles"

const Heading = ({ children, secondary, noPad }) => {
  return (
    <Box
      row
      justifyContent="center"
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: "center", md: "initial" }}
      pt={noPad ? 0 : 2}
    >
      <Main>
        {children}
        {secondary && (
          <Box as="span" display={{ xs: "none", md: "initial" }}>
            ,&nbsp;
          </Box>
        )}
      </Main>
      {secondary && <Secondary>{secondary}</Secondary>}
    </Box>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.node,
  noPad: PropTypes.bool,
}

Heading.defaultProps = {
  secondary: null,
  noPad: false,
}

export default Heading
