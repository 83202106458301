import React from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"
import { Box } from "@xstyled/styled-components"

import { Quote } from "@atoms/Typography"
import SecondaryHeading from "@atoms/SecondaryHeading"

const QuoteBlock = ({ primary }) => {
  const quote = primary.quote && RichText.asText(primary.quote)
  const author = primary.author && RichText.asText(primary.author)
  const role = primary.role && RichText.asText(primary.role)

  return (
    <Box
      as="figure"
      mx={{ xs: 2, md: 4 }}
      mt={{ xs: 10, md: 15 }}
      mb={{ xs: 4, md: 10 }}
    >
      <Quote as="blockquote" textAlign="center">
        {quote}
      </Quote>
      <Box row as="figcaption" fontSize="24px" justifyContent="center" mt={5}>
        <SecondaryHeading stack secondary={role}>
          {author}
        </SecondaryHeading>
      </Box>
    </Box>
  )
}

QuoteBlock.propTypes = {
  primary: PropTypes.shape({
    quote: PropTypes.array.isRequired,
    author: PropTypes.array.isRequired,
    role: PropTypes.array,
  }),
}

export default QuoteBlock
