import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box } from "@xstyled/styled-components"
import PropTypes from "prop-types"

import Layout from "@components/layout"
import SEO from "@atoms/Seo"
import PageWrap from "@atoms/PageWrap"
import Heading from "@atoms/Heading"
import Button from "@atoms/Button"

import Footer from "@molecules/Footer"
import MediaBlock from "@molecules/MediaBlock"
import TitleBlock from "@molecules/TitleBlock"
import QuoteBlock from "@molecules/QuoteBlock"

import { Copy, Bg } from "@styles/project"

const ProjectPage = ({ data, pageContext }) => {
  const doc = data.prismic.project.edges.slice(0, 1).pop()

  if (!doc) return null

  const project = doc.node
  const nextSlug = pageContext.next

  return (
    <Layout>
      <SEO
        title={
          (project.meta_title && RichText.asText(project.meta_title)) ||
          (project.project_title && RichText.asText(project.project_title))
        }
        description={
          (project.meta_description &&
            RichText.asText(project.meta_description)) ||
          null
        }
        image={project.meta_image && project.meta_image.url}
      />

      <Bg>
        <PageWrap as="section">
          {project.project_title && project.project_year && (
            <Heading secondary={RichText.asText(project.project_year)}>
              {RichText.asText(project.project_title)}
            </Heading>
          )}

          {project.project_description && (
            <Copy py={{ xs: 9, md: 13, lg: 20 }}>
              {RichText.asText(project.project_description)}
            </Copy>
          )}
        </PageWrap>

        <Box as="section" px={{ xs: 0, md: 4 }}>
          {project.body.map((el, i) => {
            const isCritical = i === 0
            switch (el.type) {
              case "media_row":
                return (
                  <MediaBlock
                    key={i}
                    items={el.fields}
                    loading={isCritical ? "eager" : "lazy"}
                  />
                )
              case "title":
                return <TitleBlock key={i} primary={el.primary} />
              case "quote":
                return <QuoteBlock key={i} primary={el.primary} />
              default:
                return false
            }
          })}

          <Box
            px={{ xs: 2, md: 0 }}
            pt={{ xs: 6, md: 7 }}
            pb={{ xs: 0, md: 7 }}
          >
            <Button href={`/${nextSlug}`}>Next Project</Button>
          </Box>
        </Box>
      </Bg>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($uid: String) {
    prismic {
      project: allProjects(id: $uid) {
        edges {
          node {
            project_title
            project_year
            project_description
            home_image
            meta_title
            meta_description
            meta_image
            body {
              ... on PRISMIC_ProjectBodyTitle {
                type
                primary {
                  main_title
                  secondary_title
                }
              }
              ... on PRISMIC_ProjectBodyMedia_row {
                type
                fields {
                  media {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      width
                      url
                      height
                    }
                  }
                  media_type
                  type
                  alt
                  video_height
                  video_width
                  video_sound_poster
                }
              }
              ... on PRISMIC_ProjectBodyQuote {
                type
                primary {
                  author
                  quote
                  role
                }
              }
              ... on PRISMIC_ProjectBodyButton_cta {
                type
                primary {
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  button_text
                }
              }
            }
          }
        }
      }
    }
  }
`

ProjectPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    next: PropTypes.string,
  }),
}

export default ProjectPage
