import React from "react"
import { Box } from "@xstyled/styled-components"

import PageWrap from "@atoms/PageWrap"

import { StyledFooter, StyledLink } from "./styles"

const Footer = () => {
  return (
    <StyledFooter pt={{ xs: 7, md: 16 }} pb={2}>
      <PageWrap>
        <Box row justifyContent="space-between">
          <StyledLink as="a" href="mailto:chris@biron.io">
            email
          </StyledLink>
          <StyledLink as="a" href="https://instagram.com/biron.io">
            instagram
          </StyledLink>
          <StyledLink as="a" href="https://twitter.com/biron_io">
            twitter
          </StyledLink>
          <StyledLink as="a" href="https://www.linkedin.com/in/biron-io/">
            linkedin
          </StyledLink>
        </Box>
      </PageWrap>
    </StyledFooter>
  )
}

export default Footer
