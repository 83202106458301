import styled, { variant, css, breakpoints } from "@xstyled/styled-components"

export const Desktop = styled.span(
  breakpoints({
    xs: css`
      display: ${p => (p.stack ? "none" : "inline")};
    `,
    md: css`
      display: inline;
    `,
  })
)

export const Container = styled.box(
  breakpoints({
    xs: css`
      display: flex;
      flex-direction: ${p => (p.stack ? "column" : "row")};
      text-align: ${p => (p.stack ? "center" : "left")};
      justify-content: center;

      ${variant({
        default: "medium",
        variants: {
          medium: css``,
          large: css`
            flex-direction: column;
            align-items: center;
          `,
        },
      })}
    `,
    md: css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: left;

      ${variant({
        default: "medium",
        variants: {
          medium: css``,
          large: css``,
        },
      })}
    `,
  })
)

export const Main = styled.p(
  breakpoints({
    xs: css`
      text-transform: uppercase;
      font-family: 1;
      line-height: 1;
      margin-bottom: ${p => (p.stack ? "10px" : 0)};

      ${variant({
        default: "medium",
        variants: {
          medium: css`
            font-size: 3.6vw;
          `,
          large: css`
            font-size: 3.5vw;
          `,
        },
      })}
    `,
    md: css`
      margin-bottom: 0;

      ${variant({
        default: "medium",
        variants: {
          medium: css`
            font-size: 1.7vw;
          `,
          large: css`
            font-size: 3.5vw;
          `,
        },
      })}
    `,
  })
)

export const Secondary = styled.p(
  breakpoints({
    xs: css`
      line-height: 1;
      font-family: 0;

      ${variant({
        default: "medium",
        variants: {
          medium: css`
            font-size: 3.6vw;
            transform: translateY(2px);
          `,
          large: css`
            font-size: 3.5vw;
            transform: translateY(6px);
          `,
        },
      })}
    `,
    md: css`
      ${variant({
        default: "medium",
        variants: {
          medium: css`
            font-size: 1.7vw;
            transform: translateY(2px);
          `,
          large: css`
            font-size: 3.5vw;
            transform: translateY(6px);
          `,
        },
      })}
    `,
  })
)
