import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"

import VideoPlayer from "@molecules/VideoPlayer"

import { Image, Video, Ratio } from "./styles"

const Media = ({ src, alt, type, ratio, loading, poster }) => {
  const altText = alt ? RichText.asText(alt) : "Chris Biron - Visual Designer"

  return (
    <>
      {type === "image" && (
        <Ratio ratio={ratio || 0.562}>
          {loading === "lazy" ? (
            <Image
              className="lazyload"
              data-src={src}
              alt={altText}
              loading={loading}
            />
          ) : (
            <Image src={src} alt={altText} loading={loading} />
          )}
        </Ratio>
      )}
      {type === "video" && (
        <Ratio ratio={ratio || 0.562}>
          <Video src={src} autoPlay playsInline muted loop />
        </Ratio>
      )}
      {type === "video_sound" && (
        <VideoPlayer ratio={ratio || 0.562} src={src} poster={poster} />
      )}
    </>
  )
}

Media.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ratio: PropTypes.number,
  loading: PropTypes.string,
  poster: PropTypes.string,
}

Media.defaultProps = {
  alt: null,
  ratio: 0,
  loading: "lazy",
}

export default Media
