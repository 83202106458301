import styled, { breakpoints, css } from "@xstyled/styled-components"

export const Mobile = styled.div(
  breakpoints({
    xs: css`
      display: block;
    `,
    md: `
    display: none;
  `,
  })
)

export const Desktop = styled.div(
  breakpoints({
    xs: css`
      display: none;
    `,
    md: `
    display: block;
  `,
  })
)
