import React from "react"
import PropTypes from "prop-types"

import Link from "./styles"

const Button = ({ children, href, styleVariant, my }) => {
  return (
    <Link my={my} href={href} variant={styleVariant}>
      {children}
    </Link>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  styleVariant: PropTypes.string,
  my: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Button.defaultProps = {
  styleVariant: "primary",
  my: 0,
}

export default Button
