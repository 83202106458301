import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useIdle } from "react-use"

import play from "@assets/images/play.svg"
import pause from "@assets/images/pause.svg"

import { Video, Ratio, PlayPause, Icon } from "./styles"

const VideoPlayer = ({ src, poster, ratio }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const videoRef = useRef(null)
  const isIdle = useIdle(1e3)

  const playVideo = () => {
    setVideoPlaying(true)
    videoRef.current.play()
  }

  const pauseVideo = () => {
    setVideoPlaying(false)
    videoRef.current.pause()
  }

  return (
    <Ratio ratio={ratio || 0.562}>
      {videoPlaying ? (
        <PlayPause onClick={pauseVideo} isHidden={isIdle}>
          <img src={pause} alt="pause" />
        </PlayPause>
      ) : (
        <PlayPause onClick={playVideo}>
          <Icon src={play} alt="play" />
        </PlayPause>
      )}

      <Video ref={videoRef} src={src} poster={poster ? poster.url : ""} />
    </Ratio>
  )
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  poster: PropTypes.string,
}

VideoPlayer.defaultProps = {
  ratio: 0,
}

export default VideoPlayer
