import styled, { css } from "@xstyled/styled-components"

const media = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Image = styled.img`
  ${media}
`

export const Video = styled.video`
  ${media}
`

export const Ratio = styled.div`
  overflow: hidden;
  position: relative;
  background-color: dark;
  height: 0;
  padding-bottom: ${p => p.ratio * 100}%;
`
