import styled, { breakpoints, css } from "@xstyled/styled-components"

import { MainCopy } from "@atoms/Typography"

export const Main = styled(MainCopy)(
  breakpoints({
    xs: css`
      font-size: 5vw;
      text-transform: uppercase;
      font-family: 1;
      line-height: 1.1;
    `,
    md: css`
      line-height: 1;
      font-size: 2.4vw;
    `,
  })
)

export const Secondary = styled(MainCopy)(
  breakpoints({
    xs: css`
      line-height: 1.1;
      font-size: 5vw;
      transform: translateY(0.3vw);
    `,
    md: css`
      line-height: 1;
      font-size: 2.4vw;
    `,
  })
)
