/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import PropTypes from "prop-types"

import { Desktop, Container, Main, Secondary } from "./styles"

const SecondaryHeading = ({ children, secondary, styleVariant, stack }) => {
  return (
    <Container variant={styleVariant} stack={stack}>
      <Main variant={styleVariant} stack={stack}>
        {children}
        {secondary && <Desktop stack={stack}>,&nbsp;</Desktop>}
      </Main>
      {secondary && <Secondary variant={styleVariant}>{secondary}</Secondary>}
    </Container>
  )
}

SecondaryHeading.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.node,
  styleVariant: PropTypes.string,
  stack: PropTypes.bool,
}

SecondaryHeading.defaultProps = {
  secondary: null,
  styleVariant: "medium",
  stack: false,
}

export default SecondaryHeading
