import styled, { variant, css } from "@xstyled/styled-components"

const secondary = css`
  border-color: creamLight;
  color: creamLight;
  background-color: creamDark;
`

const primary = css`
  border-color: creamDark;
  color: creamDark;
  background-color: transparent;
`

const Link = styled.aBox`
  display: block;
  padding: 3 2;
  font-size: 3;
  font-family: 1;
  text-transform: uppercase;
  border-radius: 25px;
  text-align: center;
  line-height: 1;
  border: 1px solid;

  ${variant({
    default: "primary",
    variants: {
      primary: css`
        ${primary}
        transition: 0.15s;

        &:hover {
          border-color: dark;
          background-color: dark;
          color: light;
        }
      `,
      secondary: css`
        ${secondary}
      `,
    },
  })}
`

export default Link
