import styled from "@xstyled/styled-components"

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Ratio = styled.div`
  overflow: hidden;
  position: relative;
  background-color: dark;
  height: 0;
  padding-bottom: ${p => p.ratio * 100}%;
`

export const PlayPause = styled.div`
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  width: 82px;
  height: 82px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${p => (p.isHidden ? 0 : 1)};
  transition: opacity 0.3s;
`

export const Icon = styled.img`
  margin-left: 2px;
`
