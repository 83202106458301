import React from "react"
import { Box } from "@xstyled/styled-components"
import PropTypes from "prop-types"

import Media from "../Media"

import { Mobile, Desktop } from "./styles"

const MediaBlock = ({ items, loading }) => {
  return (
    <Box my={{ xs: 0, md: 4 }}>
      {items.map(item => {
        if (!item.media || !item.media.url) return false
        const videoSize = {
          w: item.video_width,
          h: item.video_height,
        }
        const isVideo =
          item.media_type === "video" || item.media_type === "video_sound"
        let ratio = 0.562

        if (isVideo) {
          const r = videoSize.h / videoSize.w

          if (r) {
            ratio = r
          }
        } else {
          const r = item.media.height / item.media.width

          if (r) {
            ratio = r
          }
        }

        switch (item.type) {
          case "desktop":
            return (
              <Desktop key={item.media.url}>
                <Media
                  type={item.media_type}
                  src={item.media.url}
                  alt={item.alt}
                  ratio={ratio}
                  loading={loading}
                  poster={item.video_sound_poster}
                />
              </Desktop>
            )
          case "mobile":
            return (
              <Mobile key={item.media.url}>
                <Media
                  type={item.media_type}
                  src={item.media.url}
                  alt={item.alt}
                  ratio={ratio}
                  loading={loading}
                  poster={item.video_sound_poster}
                />
              </Mobile>
            )
          case "both":
            return (
              <Media
                key={item.media.url}
                type={item.media_type}
                src={item.media.url}
                alt={item.alt}
                ratio={ratio}
                loading={loading}
                poster={item.video_sound_poster}
              />
            )
          default:
            return false
        }
      })}
    </Box>
  )
}

MediaBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.object.isRequired,
      media_type: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.string,
}

export default MediaBlock
