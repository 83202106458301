import styled, { breakpoints, css } from "@xstyled/styled-components"

import { MainCopy } from "@atoms/Typography"

export const Copy = styled(MainCopy)(
  breakpoints({
    xs: css`
      font-size: 5.5vw;
      line-height: 1.4;
    `,
    md: css`
      font-size: 3vw;
      line-height: 1.15;
    `,
  })
)

export const Bg = styled.div`
  background-color: light;
`
